import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Divider } from 'antd';
import { connect } from 'react-redux';
import DynamicField from '../DynamicField';

const General = ({
    form, values, onUpdate, patientsList, user, ageNumber,
}) => {
    const [showCustomId, setShowCustomId] = useState(false);
    const [customId, setCustomId] = useState(0);

    useEffect(() => {
        let patients = [];
        patients = patientsList;
        if (!patients.length) setCustomId('001');
        if (patients.length) {
            const lastObject = patients.reduce((r, a) => (r.createdAt > a.createdAt ? r : a));
            let customId = lastObject.customId
                ? lastObject.customId.substr(lastObject.customId.lastIndexOf('-') + 1)
                : '0';
            parseInt(customId, 10);
            customId++;
            const padStart = customId.toString().padStart(3, 0);
            setCustomId(padStart);
        }
    }, [patientsList]);

    const handleValuesChange = (val) => {
        if (val.consentSign === 'Oui') setShowCustomId(true);

        if (val.consentSign === 'Non') setShowCustomId(false);
    };

    return (
        <Form
            style={{ marginTop: 30 }}
            form={form}
            onValuesChange={handleValuesChange}
        >
            <div
                className="flex flex-wrap"
                style={{
                    justifyContent: 'space-between',
                    padding: '0 34px',
                    marginRight: '10%',
                }}
            >
                {user && user.type === 1 ? (
                    <div>
                        {['firstName', 'lastName', 'gender', 'birthDate'].map(k => (
                            <DynamicField
                                key={k}
                                field={values[k]}
                                onUpdate={onUpdate}
                                fieldFamily="general"
                            />
                        ))}
                        { ageNumber
                            ? ageNumber >= 18 && ageNumber <= 50 ? (
                                <p style={{ color: 'green' }}>
                                    Age :
                                    {ageNumber}
                                    {' '}
                                    ans
                                </p>
                            ) : (
                                <p style={{ color: 'red' }}>
                                    Age :
                                    {ageNumber}
                                    {' '}
                                    ans
                                </p>
                            )
                            : null
                        }
                    </div>
                ) : (
                    <div>
                        {['gender', 'birthDate'].map(k => (
                            <DynamicField
                                key={k}
                                field={values[k]}
                                onUpdate={onUpdate}
                                fieldFamily="general"
                            />
                        ))}
                    </div>
                )}
            </div>
            <Divider />
            {['consentSign', 'consentDate'].map(k => (
                <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="general"
                />
            ))}
            {showCustomId ? (
                <strong>
                    Identifiant du patient: BEALL-212-
                    {customId}
                </strong>
            ) : null}
        </Form>
    );
};

General.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    user: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
};

General.defaultProps = {
    onUpdate: () => {},
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
    user: state.auth.user,
});

export default connect(stateToProps)(General);
