import React, { useState } from 'react';
import { Menu, Layout } from 'antd';
import PropTypes from 'prop-types';
import {
    ContactsOutlined, CarryOutOutlined, SecurityScanOutlined,
    ExceptionOutlined, IssuesCloseOutlined, QuestionCircleOutlined, CheckCircleOutlined,
    FieldBinaryOutlined, FieldNumberOutlined, DatabaseOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppLogo from './AppLogo';
import { navigateTo } from '../../history';
import { selectVisit } from '../../redux/visits/actions';

const { SubMenu } = Menu;
const { Sider } = Layout;

const SideBar = ({ selectedItem, selectVisit, user }) => {
    const [collapsed, setCollapsed] = useState(window.innerWidth <= 600 || false);
    // const disabledItems = !user.email_verified || !isConfigComplete(user);
    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => {
                setCollapsed(!collapsed);
            }}
            className="layout-bg"
        >
            <AppLogo showText={!collapsed} />
            <Menu theme="light" mode="inline" selectedKeys={[selectedItem]} className="side-bar-menu">
                <Menu.Item key="/patients" onClick={() => navigateTo('/patients')} icon={<ContactsOutlined />}>
                    Liste des Volontaires
                </Menu.Item>
                <SubMenu title="Visites" key="visits" icon={<CarryOutOutlined />}>
                    <SubMenu title="Visite 1" key="visit1" icon={<FieldBinaryOutlined />}>
                        <Menu.Item key="/j0" onClick={() => { selectVisit(null); navigateTo('/j0'); }}>Visite 1 J0</Menu.Item>
                        <Menu.Item key="/j1" onClick={() => { selectVisit(null); navigateTo('/j1'); }}>Visite 1 J1</Menu.Item>
                        <Menu.Item key="/j2" onClick={() => { selectVisit(null); navigateTo('/j2'); }}>Visite 1 J2</Menu.Item>
                    </SubMenu>
                    <SubMenu title="Visite 2" key="visit2" icon={<FieldNumberOutlined />}>
                        <Menu.Item key="/j7" onClick={() => { selectVisit(null); navigateTo('/j7'); }}>Visite 2 J7</Menu.Item>
                        <Menu.Item key="/j8" onClick={() => { selectVisit(null); navigateTo('/j8'); }}>Visite 2 J8</Menu.Item>
                        <Menu.Item key="/j9" onClick={() => { selectVisit(null); navigateTo('/j9'); }}>Visite 2 J9</Menu.Item>

                    </SubMenu>

                </SubMenu>
                <SubMenu title="Queries" key="queries" icon={<SecurityScanOutlined />}>
                    <Menu.Item key="/open" onClick={() => navigateTo('/open')} icon={<QuestionCircleOutlined />}> En attente </Menu.Item>
                    <Menu.Item key="/answered" onClick={() => navigateTo('/answered')} icon={<CheckCircleOutlined />}> Traitées </Menu.Item>
                    <Menu.Item key="/closed" onClick={() => navigateTo('/closed')} icon={<IssuesCloseOutlined />}> Fermées</Menu.Item>
                </SubMenu>
                <Menu.Item key="/adverse" onClick={() => navigateTo('/adverse')} icon={<ExceptionOutlined />}>
                    Evenements indésirables
                </Menu.Item>
                {user && user.type === 2
                    ? (
                        <SubMenu title="Extraction" key="extract" icon={<DatabaseOutlined />}>
                            <Menu.Item key="/extract-patients" onClick={() => { navigateTo('/extract-patients'); }} icon={<ContactsOutlined />}>Patients</Menu.Item>
                            <Menu.Item key="/extract-visits" onClick={() => { navigateTo('/extract-visits'); }} icon={<CarryOutOutlined />}> Visites</Menu.Item>
                        </SubMenu>
                    )
                    : null }
            </Menu>
        </Sider>
    );
};

SideBar.propTypes = {
    selectedItem: PropTypes.string.isRequired,
    selectVisit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectVisit,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(SideBar);
