export default {
    consultationDate: {
        name: 'consultationDate',
        label: 'Date de la consultation',
        type: 'datetime',
        rules: ['required'],
        strongLabel: true,
    },
    admissionDate: {
        name: 'admissionDate',
        label: "Date d'admission",
        rules: ['required'],
        type: 'datetime',
        strongLabel: true,
    },
    lastMeal: {
        name: 'lastMeal',
        label: 'Dernière prise de repas',
        rules: ['required'],
        type: 'datetime',
        strongLabel: true,
    },
    lastDrink: {
        name: 'lastDrink',
        label: 'Dernière prise de boisson',
        rules: ['required'],
        type: 'datetime',
        strongLabel: true,
    },
    systolique: {
        name: 'systolique',
        rules: ['required'],
        type: 'inputNumber',
        label: 'PAS membre gauche',
        placeholder: 'TA systolique',
        strongLabel: true,
    },
    diastolique: {
        name: 'diastolique',
        rules: ['required'],
        type: 'inputNumber',
        label: 'PAD membre gauche',
        placeholder: 'TA diastolique',
        strongLabel: true,

    },
    systoliqueRight: {
        name: 'systoliqueRight',
        rules: ['required'],
        type: 'inputNumber',
        label: 'PAS membre droite',
        placeholder: 'TA systolique',
        strongLabel: true,
    },
    diastoliqueRight: {
        name: 'diastoliqueRight',
        rules: ['required'],
        type: 'inputNumber',
        label: 'PAD membre droite',
        placeholder: 'TA diastolique',
        strongLabel: true,
    },
    bodyTemperature: {
        name: 'bodyTemperature',
        rules: ['required'],
        type: 'inputNumber',
        label: 'Température (°C)',
        strongLabel: true,
    },
    sampleTime: {
        name: 'sampleTime',
        rules: ['required'],
        type: 'timepicker',
        label: 'Heure du prélèvement',
        strongLabel: true,
    },
    firstTube: {
        name: 'firstTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (00:00)',
        strongLabel: true,
    },
    secondTube: {
        name: 'secondTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (00:20)',
        strongLabel: true,
    },
    thirdTube: {
        name: 'thirdTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (00:40)',
        strongLabel: true,
    },
    fourthTube: {
        name: 'fourthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (01:00)',
        strongLabel: true,
    },
    fifthTube: {
        name: 'fifthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (01:20)',
        strongLabel: true,
    },
    SixthTube: {
        name: 'SixthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (01:40)',
        strongLabel: true,
    },
    seventhTube: {
        name: 'seventhTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (02:00)',
        strongLabel: true,
    },
    eighthTube: {
        name: 'eighthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (03:00)',
        strongLabel: true,
    },
    ninthTube: {
        name: 'ninthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (04:00)',
        strongLabel: true,
    },
    tenthTube: {
        name: 'tenthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (06:00)',
        strongLabel: true,
    },
    eleventhTube: {
        name: 'eleventhTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (08:00)',
        strongLabel: true,
    },
    twelfthTube: {
        name: 'twelfthTube',
        rules: ['required'],
        type: 'input',
        label: 'Tube (10:00)',
        strongLabel: true,
    },
    Thirteenth:{
        name: 'Thirteenth',
        rules: ['required'],
        type: 'input',
        label: 'Tube (12:00)',
        strongLabel: true,        
    },
    twentyfourth:{
        name: 'twentyfourth',
        rules: ['required'],
        type: 'input',
        label: 'Tube (24:00)',
        strongLabel: true,        
    },
    thirtysixth:{
        name: 'thirtysixth',
        rules: ['required'],
        type: 'input',
        label: 'Tube (36:00)',
        strongLabel: true,        
    },
    fourtyeigth:{
        name: 'fourtyeigth',
        rules: ['required'],
        type: 'input',
        label: 'Tube (48:00)',
        strongLabel: true,        
    },
    administratedDrug: {
        name: 'administratedDrug',
        label: 'Médicament à administrer',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Allegra', value: 'Allegra' },
            { label: 'Xyzall', value: 'Xyzall' },
        ],
        strongLabel: true,
    },
    adminstratedDate: {
        name: 'adminstratedDate',
        label: "Date d'administration",
        type: 'datetime',
        rules: ['required'],
        strongLabel: true,
    },
    administratedHour: {
        name: 'administratedHour',
        rules: ['required'],
        type: 'timepicker',
        label: "Heure d'administration",
        strongLabel: true,
    },
    fluidsNumber: [],
    fluidsNumberProps: {
        name: 'fluidsNumberProps',
        sdv: false,
        query: false,
        frozen: false,
    },

};
