import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Radio, Descriptions, TimePicker, DatePicker } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  setVisitsList,
  getVisitsByPatientAsync,
} from "../../../redux/visits/actions/index";
import FORM_VALIDATORS from "../../../helpers/formRules";
import "./drugStyles.css";

const drugForm = ({
  form,
  visit,
  patient,
  visitsList,
  getVisitsByPatientAsync,
  setVisitsList,
}) => {
  const [medoc, setMedoc] = useState("");
  //const medicamentToTake = parseInt(patient.customId.split('-')[2]);
  useEffect(() => {
    if (visit === "j7") {
      getVisitsByPatientAsync(patient._id);
    }
    return () => {
      setVisitsList([]);
    };
  }, [patient]);

  useEffect(() => {
    visitsList.forEach((e) => {
        e.visitNumber === "j0" && setMedoc(e.administratedDrug.value);
    });
  },[visitsList]);
  
  console.log("med",medoc)
  return (
    <Form style={{ marginTop: 30 }} form={form}>
      <div
        className="flex"
        style={{ alignItems: "baseline", flexDirection: "column" }}
        id="drugForm"
      >
        <Descriptions>
          <Descriptions.Item>Médicament à administrer</Descriptions.Item>
          <Descriptions.Item>
            {visit === "j0" ? (
              <Form.Item
                style={{ width: 200 }}
                rules={[FORM_VALIDATORS.required]}
                className="m-right-medium"
                name="administratedDrug"
                //initialValue={medicamentToTake % 2 === 0 ? 'Allegra' : 'Xyzall'}
              >
                <Radio.Group>
                  <Radio value="Allegra">Allegra</Radio>
                  <Radio value="Xyzall">Xyzall</Radio>
                </Radio.Group>
              </Form.Item>
            ) : null}

            {(visit === "j7" && medoc !=="") && (
              <Form.Item
                style={{ width: 200 }}
                className="m-right-medium"
                name="administratedDrug"
                initialValue={`${medoc !== "Xyzall" ? "Xyzall" : "Allegra" }`}
              >
                <Radio.Group disabled={visit === "j7"}>
                  <Radio value="Allegra">Allegra</Radio>
                  <Radio value="Xyzall">Xyzall</Radio>
                </Radio.Group>
              </Form.Item>
            )
            }

            {/* {visit === 'j7' && medoc !== "Xyzall" ? (

                        ) : null} */}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item>Date d'administration</Descriptions.Item>
          <Form.Item
            style={{ width: 200 }}
            className="m-right-medium"
            name="adminstratedDate"
            rules={[FORM_VALIDATORS.required]}
          >
            <DatePicker
              onChange={(date) => {
                form.setFieldsValue({
                  adminstratedDate: date
                    ? moment(date.toDate()).format("L")
                    : null,
                });
              }}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item>Heure d'administration</Descriptions.Item>
          <Form.Item
            style={{ width: 200 }}
            className="m-right-medium"
            name="administratedHour"
            rules={[FORM_VALIDATORS.required]}
          >
            <TimePicker
              onChange={(time) => {
                console.log("time picker change", time.toDate());
                form.setFieldsValue({
                  administratedHour: time
                    ? moment(time.toDate()).format("HH:mm")
                    : null,
                });
              }}
              format="HH:mm"
            />
          </Form.Item>
        </Descriptions>
      </div>
    </Form>
  );
};

drugForm.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setVisitsList: PropTypes.func.isRequired,
  getVisitsByPatientAsync: PropTypes.func.isRequired,
  visitsList: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  visitsList: state.visits.visitsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVisitsByPatientAsync,
      setVisitsList,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(drugForm);
