import React, { useEffect } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import { Button, Table } from "antd";
import { formatDateTimeFromIso } from "../../../helpers/functions";

const BloodSamplesList = ({ bloodSamples, visit, setSelectedBloodSample }) => {
  
  useEffect(() => {
    if (visit === "j0" || visit === "j7") {
      Object.keys(bloodSamples).map((e) => {
        if (e === "0") {
          bloodSamples["0"].dose = "(Pré-dose)";
        }
        return e;
      });
    }
  });

  const getRecordValue = (record) => {
    return Object.values(record).find((e) => e["type"] === "input");
  };
  const columns = [
    {
      title: <b> Heure de prélèvement </b>,
      key: "sampleTime",
      render: (_, record) => {
        // const isPredose = record.dose === "(Pré-dose)";
        const momentDate = moment(record.sampleTime.value).local();
        /*
        const momentDate = isPredose
          ? moment(record.sampleTime.value).local()
          : moment(record.sampleTime.value).utc();
        */

        return (
          <p>
            {`${momentDate.format("DD MMM YYYY, HH:mm")} ${
              record.dose ? record.dose : ""
            }`}
          </p>
        );
        // return (
        // <p>
        //   {`${formatDateTimeFromIso(record.sampleTime.value)} ${
        //     record.dose ? record.dose : ""
        //   }`}
        // </p>
        // )
      },
    },
    {
      title: <b>Tube </b>,
      key: "firstTube",
      render: (t, record) => {
        const valueRecord = getRecordValue(record);
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{valueRecord ? valueRecord.value : null}</div>
            <div>{valueRecord ? valueRecord.label : ""}</div>
            <div>
              <Button onClick={() => setSelectedBloodSample(valueRecord.name)}>
                Modifier
              </Button>
            </div>
          </div>
        );
      },
    },
    // {
    //     title: <b>Tube N°2 </b>,
    //     key: 'secondTube',
    //     render: (_, record) => <p>{record.secondTube.value}</p>,
    // },
  ];
  
  return (
    <div className="section-content">
      <Table
        locale={{ emptyText: "Aucun Prévèlevement" }}
        columns={columns}
        dataSource={bloodSamples}
      />
    </div>
  );
};

BloodSamplesList.propTypes = {
  bloodSamples: PropTypes.array.isRequired,
  visit: PropTypes.string.isRequired,
};

export default BloodSamplesList;
