
import { DateTime } from 'luxon';

export const getFullName = user => (`${user.lastName} ${user.firstName}`);
export const getPatientFullName = user => (`${user.lastName.value} ${user.firstName.value}`);
export const getUserInitials = user => (`${user.lastName[0]} ${user.firstName[0]}`);
export const getFullNameInversed = user => `${user.firstName} ${user.lastName}`;

export const formatDateTimeFromIso = date => (date ? DateTime.fromISO(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATETIME_MED) : '');


export const calculateBMI = (weight, height) => (weight / ((height / 100) ** 2)).toFixed(2);

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getTimeFromISODate = isoDate => isoDate.substr(11, 5);

export const dateTimeFromTimestamp = date => (date
    ? DateTime.fromMillis(date)
        .setLocale('fr')
        .toLocaleString(DateTime.TIME_24_SIMPLE)
    : '');

export const parseVisitName = (visitName) => {
    let visit;
    switch (visitName) {
        case 'j0':
        case 'j1':
        case 'j2':
            visit = `Visite 1 ${visitName.toUpperCase()}`;
            break;
        case 'j7':
        case 'j8':
        case 'j9':
            visit = `Visite 2 ${visitName.toUpperCase()}`;
            break;
        default:
            break;
    }
    return visit;
};

export const structureFields = (initialValues, obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    const res = {};

    //console.log('[debug] structureFields - ', {initialValues, obj, keys, values});

    keys.map((key, i) => {
        res[key] = {
            ...initialValues[key],
            value: values[i],
            sdv: false,
            query: false,
            frozen: false,
            readOnly: true,
        };

        //console.log('[debug] structureFields - keys.map - ', {res, ['res[key]']: res[key], i});
        return res;
    });
    return res;
};

export const structureFieldsEvent = (initialValues, obj) => {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    const res = {};
    keys.map((key, i) => {
        res[key] = {
            ...initialValues[key],
            value: values[i],
            sdv: false,
            query: false,
            frozen: false,
            readOnly: false,
        };
        return res;
    });
    return res;
};
