export default {
    firstName: {
        name: 'firstName',
        label: 'Prénom',
        rules: [''],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
    },
    lastName: {
        name: 'lastName',
        label: 'Nom',
        rules: [''],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,

    },
    gender: {
        name: 'gender',
        label: 'Sexe',
        rules: [''],
        type: 'radio',
        radioFields: [
            { label: 'Homme', value: 'Homme' },
            { label: 'Femme', value: 'Femme' },
        ],
        strongLabel: true,

    },
    birthDate: {
        name: 'birthDate',
        label: 'Date de naissance',
        rules: ['required', 'validDate'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        placeholder: 'e.g: 01/01/2000',
        strongLabel: true,
    },
    weight: {
        name: 'weight',
        label: 'Poids',
        rules: [''],
        type: 'inputNumber',
        minNumber: 1,
        unity: 'Kg',
        strongLabel: true,

    },
    height: {
        name: 'height',
        label: 'Taille',
        rules: [''],
        type: 'inputNumber',
        minNumber: 10,
        unity: 'Cm',
        strongLabel: true,

    },
    systolique: {
        name: 'systolique',
        rules: [''],
        type: 'inputNumber',
        label: 'PAS membre gauche',
        // placeholder: 'TA systolique',
        unity: 'mmHg',
        strongLabel: true,

    },
    systoliqueRight: {
        name: 'systoliqueRight',
        rules: [''],
        type: 'inputNumber',
        label: 'PAS membre droit',
        // placeholder: 'TA systolique',
        unity: 'mmHg',
        strongLabel: true,

    },
    diastolique: {
        name: 'diastolique',
        rules: [''],
        type: 'inputNumber',
        label: 'PAD membre gauche',
        unity: 'mmHg',
        // placeholder: 'TA diastolique',
        strongLabel: true,

    },
    diastoliqueRight: {
        name: 'diastoliqueRight',
        rules: [''],
        type: 'inputNumber',
        label: 'PAD membre droit',
        unity: 'mmHg',
        // placeholder: 'TA diastolique',
        strongLabel: true,

    },
    bodyTemperature: {
        name: 'bodyTemperature',
        rules: [''],
        type: 'inputNumber',
        label: 'Température',
        unity: '°C',
        strongLabel: true,

    },
    consentSign: {
        name: 'consentSign',
        label: 'Signature du consentement',
        rules: [''],
        type: 'radio',
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
        strongLabel: true,

    },
    consentDate: {
        name: 'consentDate',
        label: 'Date de signature du consentement (jj/mm/aaaa)',
        rules: ['validDate'],
        type: 'input',
        inputStyle: { width: 'min-content' },
        placeholder: 'e.g: 01/01/2000',
        strongLabel: true,

    },
    biologicalExamination: {
        // NFS: {
        //     name: 'NFS',
        //     label: 'NFS',
        //     rules: [''],
        //     type: 'inputNumber',
        //     strongLabel: true,

        // },
        hemoglobine: {
            name: 'hemoglobine',
            label: 'Hemoglobine',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/dl',
            warning: false,
            // placeholder: '[150-400]',
            strongLabel: true,

        },
        leucocytes: {
            name: 'leucocytes',
            label: 'Leucocytes',
            rules: [''],
            type: 'inputNumber',
            unity: '10^9/L',
            warning: false,
            // placeholder: '[150-400]',
            strongLabel: true,
        },
        Plaquettes: {
            name: 'Plaquettes',
            label: 'Plaquettes',
            rules: [''],
            type: 'inputNumber',
            unity: '10e3/µL',
            warning: false,
            placeholder: '[150-400]',
            strongLabel: true,

        },
        VS: {
            name: 'VS',
            label: 'VS',
            rules: [''],
            type: 'inputNumber',
            unity: 'mm/h',
            strongLabel: true,

        },
        CRP: {
            name: 'CRP',
            label: 'CRP',
            rules: [''],
            type: 'inputNumber',
            unity: 'mg/L',
            strongLabel: true,

        },
        ASAT: {
            name: 'ASAT',
            label: 'ASAT',
            rules: [''],
            type: 'inputNumber',
            unity: 'UI/L',
            warning: false,
            placeholder: '[0-32]',
            strongLabel: true,

        },
        ALAT: {
            name: 'ALAT',
            label: 'ALAT',
            rules: [''],
            type: 'inputNumber',
            unity: 'UI/L',
            warning: false,
            placeholder: '[10-33]',
            strongLabel: true,

        },
        'Bilirubine totale': {
            name: 'Bilirubine totale',
            label: 'Bilirubine totale',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[0-10]',
            strongLabel: true,


        },
        'Bilirubine conjuguée': {
            name: 'Bilirubine conjuguée',
            label: 'Bilirubine conjuguée',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[0-2]',
            strongLabel: true,


        },
        // 'Phosphatase alcaline': {
        //     name: 'Phosphatase alcaline',
        //     label: 'Réserves alcalines',
        //     rules: [''],
        //     type: 'inputNumber',
        //     palceholder: '[35-104]',
        //     strongLabel: true,
        //     unity: 'mmol/L',
        // },        
        
        'Phosphatase alcaline': {
            name: 'Phosphatase alcaline',
            label: 'Phosphatase alcaline',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[35-104]',
            strongLabel: true,
            unity: 'U/L',
        },
        'Réserves alcalines': {
            name: 'Réserves alcalines',
            label: 'Réserves alcalines',
            rules: [''],
            type: 'inputNumber',
            palceholder: '',
            strongLabel: true,
            unity: 'mmol/L',
        },        
        'K+': {
            name: 'K+',
            label: 'K+',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[3.50-5.10]',
            strongLabel: true,

        },
        'NA+': {
            name: 'NA+',
            label: 'NA+',
            rules: [''],
            type: 'inputNumber',
            palceholder: '[135-145]',
            strongLabel: true,

        },
        'Ca++': {
            name: 'Ca++',
            label: 'Ca++',
            rules: [''],
            type: 'inputNumber',
            placeholder: '[86-102]',
            strongLabel: true,

        },
        'Mg+': {
            name: 'Mg+',
            label: 'Mg+',
            rules: [''],
            type: 'inputNumber',
            strongLabel: true,

        },
        Urée: {
            name: 'Urée',
            label: 'Urée',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[0.16-0.48]',
            strongLabel: true,

        },
        Créatinine: {
            name: 'Créatinine',
            label: 'Créatinine',
            rules: [''],
            type: 'inputNumber',
            unity: 'mg/L',
            warning: false,
            placeholder: '[5-9]',
            strongLabel: true,

        },
        Glycémie: {
            name: 'Glycémie',
            label: 'Glycémie',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[0.70-1.10]',
            strongLabel: true,

        },
        Protides: {
            name: 'Protides',
            label: 'Protides totaux',
            rules: [''],
            type: 'inputNumber',
            unity: 'g/L',
            warning: false,
            palceholder: '[65-80]',
            strongLabel: true,

        },
        pregnancyTest: {
            name: ['pregnancyTest'],
            label: 'Test de grossesse',
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Négatif', value: 'Négatif' },
                { label: 'Positif', value: 'Positif' },
            ],
            strongLabel: true,

        },
    },
    radiologicalExamination: {
        thorax: {
            name: 'thorax',
            label: 'Radio thorax : normale',
            strongLabel: true,
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],

        },
        ECG: {
            name: 'ECG',
            label: 'ECG : normal',
            strongLabel: true,
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],

        },
    },
    inclusion: {
        age: {
            name: 'age',
            label: 'Âge compris entre 18 et 55 ans (adulte)',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        goodHealth: {
            name: 'goodHealth',
            label:
        "Volontaires de sexe masculin et féminin en bonne santé selon les antécédents médicaux et l'examen physique et si nous ne disposons d’aucune contre indication pour le sexe féminin.",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],

        },
        contraception: {
            name: 'contraception',
            label: 'Contraception efficace pour les volontaires de sexe féminin',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        signedConsent: {
            name: 'signedConsent',
            label: 'Consentement éclairé signé',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        smoker: {
            name: 'smoker',
            label:
        'Non-fumeurs ou modérément fumeurs (inférieur à 10 cigarettes par jour) avec un arrêt la veille de l’hospitalisation',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        alcohol: {
            name: 'alcohol',
            label: 'Abstème (pas de consommation d’alcool)',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        BMI: {
            name: 'BMI',
            label: 'Volontaires ayant une IMC entre 18,5 et 30 kg/m2',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        biology: {
            name: 'biology',
            label: 'Résultats des examens biologiques normaux',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        followProcedure: {
            name: 'followProcedure',
            label:
        "Volontaires disposés à se rendre disponibles pour la durée de l'étude et sont disposés à suivre les procédures de l'étude",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
    },
    exclusion: {
        anotherTrial: {
            name: 'anotherTrial',
            label: 'Participation dans une autre étude clinique',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        age: {
            name: 'age',
            label: 'Âge <18 ans ou > à 55 ans',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        pharmaceuticals: {
            name: 'pharmaceuticals',
            label: 'Prise concomitante de médicaments',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        chronicalDisease: {
            name: 'chronicalDisease',
            label:
        'Volontaires présentant des signes cliniques de maladies aiguës ou porteurs de maladies chroniques',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        allergy: {
            name: 'allergy',
            label:
        "Allergies connues à la lévocétirizine ou à d'autres dérivés de la pipérazine",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        anomalyECG: {
            name: 'anomalyECG',
            label: "Une anomalie de l'ECG à 12 dérivations",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        HTA: {
            name: 'HTA',
            label:
        'Patients hypertendus (tension artérielle systolique en décubitus dorsal supérieure ou égale à 140 mmHg ou tension artérielle diastolique en décubitus dorsal supérieure ou égale à 90 mmHg)',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        antecedents: {
            name: 'antecedents',
            label:
        "Antécédents de troubles cardiovasculaires, respiratoires, hépatiques, rénaux, gastro-intestinaux, endocriniens, hématologiques, psychiatriques ou neurologiques susceptibles d'altérer significativement l'absorption, le métabolisme ou l'élimination des médicaments ou de constituer un risque lors de la prise du médicament à l'étude ou d’interférer avec l'interprétation des données",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        drugs: {
            name: 'drugs',
            label: 'Consommation régulière des drogues ou des stupéfiants',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        usageDuringTrial: {
            name: 'usageDuringTrial',
            label:
        "Utilisation ou l'intention d'utiliser des médicaments 7 et 14 jours respectivement avant l'administration",
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        VIH: {
            name: 'VIH',
            label: 'Volontaires avec tests positifs au VIH, hépatite B ou C',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
        pregnant: {
            name: 'pregnant',
            label: 'Femme enceinte ou allaitante',
            strongLabel: true,
            style: { maxWidth: '80%', margin: 'auto' },
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Oui' },
                { label: 'Non', value: 'Non' },
            ],
        },
    },
    decision: {
        status: {
            name: 'status',
            label: 'Participant éligible à l’étude',
            rules: [''],
            type: 'radio',
            radioFields: [
                { label: 'Oui', value: 'Participe' },
                { label: 'Non', value: 'Ne participe pas' },
            ],
            strongLabel: true,
        },
        nextVisitDate: {
            name: 'nextVisitDate',
            label: 'Prochaine date pour la visite V1-J0 : Hospitalisation',
            rules: [],
            type: 'input',
            inputStyle: { width: 'min-content' },
            placeholder: 'e.g: 01/01/2000',
            strongLabel: true,
        },
        descirptionNonEligibile: {
            name: 'descirptionNonEligibile',
            label: 'Description de non élegibilté du volontaire sain',
            rules: [''],
            inputStyle: { width: 'min-content' },
            type: 'inputTextArea',
            strongLabel: true,
        },
    },

    medicalAntecedents: [],
    treatments: [],
    medicalAntecedentsProps: {
        name: 'medicalAntecedentsProps',
        sdv: false,
        query: false,
        frozen: false,
    },
    treatmentsProps: {
        name: 'treatmentsProps',
        sdv: false,
        query: false,
        frozen: false,
    },
};
