import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import DynamicField from '../../../components/DynamicField';

const Decision = ({
    form, values, onUpdate, status,
}) => (
    <Form form={form}>
        <DynamicField field={values.decision.status} onUpdate={onUpdate} fieldFamily="decision" />
        {
            status === 'Ne participe pas'
                ? <DynamicField field={values.decision.descirptionNonEligibile} onUpdate={onUpdate} fieldFamily="decision" />
                : null
        }
        <DynamicField field={values.decision.nextVisitDate} onUpdate={onUpdate} fieldFamily="decision" />
    </Form>
);

Decision.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default Decision;
