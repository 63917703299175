import React, { useState, useEffect } from 'react';
import {
    Form,
    Button,
    Typography,
    Divider,
} from 'antd';
import PropTypes from 'prop-types';
import './styles.css';
import DynamicField from '../../components/DynamicField/index';


const { Title } = Typography;

const AdverseForm = ({
    form, values, onFinish, onUpdate, adverseEvent,
}) => {
    const [extraIntensity, setExtraIntensity] = useState(false);

    const handleValuesChange = (val) => {
        if (val.intensity && val.intensity === 'Grave') setExtraIntensity(true);
        if (val.intensity && val.intensity === 'Non grave') setExtraIntensity(false);
    };

    useEffect(() => {
        if (adverseEvent) {
            if (adverseEvent.intensity.value === 'Grave') setExtraIntensity(true);
            if (adverseEvent.intensity.value === 'Non grave') setExtraIntensity(false);
        }
    }, [adverseEvent]);


    return (
        <Form style={{ marginTop: 30, display: 'flex' }} onFinish={onFinish} form={form} onValuesChange={handleValuesChange}>
            <div style={{ padding: '0 34px', marginRight: '10%' }}>
                {['description', 'startDate', 'endDate', 'causality'].map(k => (
                    <DynamicField key={k} field={values[k]} fieldFamily="adverse" onUpdate={onUpdate} />
                ))}
                <DynamicField field={values.intensity} fieldFamily="adverse" onUpdate={onUpdate} />
                {extraIntensity ? (
                    <div>
                        <Divider />
                        <Title style={{ marginBottom: 30 }} level={5}>
                            Evénement indésirable grave
                        </Title>
                        <DynamicField field={values.declaration} fieldFamily="adverse" onUpdate={onUpdate} />
                        <DynamicField field={values.issue} fieldFamily="adverse" onUpdate={onUpdate} />
                        <Divider />
                    </div>
                ) : null}
                <DynamicField field={values.exclusion} fieldFamily="adverse" onUpdate={onUpdate} />
                <DynamicField field={values.evolution} fieldFamily="adverse" onUpdate={onUpdate} />
                {adverseEvent ? null
                    : (
                        <Form.Item>
                            <Button type="primary" className="center-button" htmlType="submit">
                                Envoyer
                            </Button>
                        </Form.Item>
                    )
                }
            </div>
        </Form>
    );
};

AdverseForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    adverseEvent: PropTypes.object.isRequired,

};

export default AdverseForm;
