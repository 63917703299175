
import React from 'react';
import PropTypes from 'prop-types';
import {
    Form,
} from 'antd';
import DynamicField from '../../components/DynamicField';

const VisitInformations = ({
    form, values, onUpdate, visit,
}) => (
    <Form style={{ marginTop: 30 }} form={form}>
        <div>
            {visit === 'j0' || visit === 'j7'
                ? ['consultationDate', 'admissionDate', 'lastMeal', 'lastDrink'].map(k => (
                    <DynamicField key={k} field={values[k]} onUpdate={onUpdate} fieldFamily="visitInformations" />
                ))
                : ['consultationDate'].map(k => (
                    <DynamicField key={k} field={values[k]} onUpdate={onUpdate} fieldFamily="visitInformations" />
                ))
            }
        </div>
    </Form>
);

VisitInformations.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    visit: PropTypes.func.isRequired,
};

VisitInformations.defaultProps = {
    onUpdate: () => {},
};

export default VisitInformations;
