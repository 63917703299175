/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Button, Typography } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import moment from "moment";
import {
  setPatientsList,
  setPendingListAsync,
} from "../../../redux/patients/actions";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import PatientsList from "./PatientsList";
import emptyValues from "./emptyValues";
import VisitInformations from "../VisitInformations";
//import FluidsNumber from "./FluidsNumber";
import { parseVisitName, structureFields } from "../../../helpers/functions";
import Clinical from "../../../components/medicalRecord/clinical";
import DrugForm from "./drugForm";
import BloodSample from "../BloodSample";
import { createVisitAsync } from "../../../redux/visits/actions";
import "./stylesIndex.css";

const { Step } = Steps;
const { Title } = Typography;

const AddVisit = ({
  contentIsLoading,
  setPendingListAsync,
  visit,
  patientsList,
  createVisitAsync,
  setPatientsList,
  closeAdd,
}) => {
  const [current, setCurrent] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [patient, selectPatient] = useState(null);
  const [values, setValues] = useState(emptyValues);
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [formSubmitting, setFormSubmitting] = useState(false);

  // const onChange = (name, value) => {
  //   setValues({ ...values, [name]: value });
  // };

  useEffect(() => {
    setPendingListAsync(visit, closeAdd);
    return () => {
      setPatientsList([]);
    };
  }, []);

  useEffect(
    () => () => {
      setPatientsList([]);
      closeAdd();
    },
    [visit]
  );


  useEffect(() => {
    if (formSubmitting) {
      createVisitAsync({ ...values, patient, visitNumber: visit });

      setFormSubmitting(false);
      setValues(null);
      setValues(emptyValues);
      closeAdd();
    }
    if (current === 0 && patient) {
      selectPatient(null);
    }
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    } else {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        case 4:
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    }
  }, [current, formSubmitting]);

  const handleNext = async (data) => {
    try {
      let vals;
      if (visit === "j0" || visit === "j7") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            // visitInformationsForm.setFieldsValue({
            //   admissionDate: {
            //     date: data.decision.nextVisitDate.value,
            //     time: moment(),
            //   },
            // });
            break;
          case 1:
            vals = await visitInformationsForm.validateFields();
            const newValues = {
              ...values,
              ...structureFields(values, vals),
            };
            setValues(newValues);
            setCurrent(current + 1);
            break;
          // case 2:
          //   setCurrent(current + 1);
          //   break;
          case 2:
            vals = await clinicalForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          case 4:
            if (visit === "j0") {
              vals = {
                administratedDrug: drugForm.getFieldValue("administratedDrug"),
                adminstratedDate: drugForm.getFieldValue("adminstratedDate"),
                administratedHour: drugForm.getFieldValue("administratedHour"),
              };
              setValues({
                ...values,
                ...structureFields(values, vals),
              });
            } else if (visit === "j7") {
              setValues({
                ...values,
                ...structureFields(values, {
                  administratedDrug: drugForm.getFieldValue(
                    "administratedDrug"
                  ),
                }),
              });
            }
            setCurrent(current + 1);
            break;
          case 3:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            vals = await bloodSampleForm.validateFields();
            const newBloodSamplesArray = values.bloodSamples
              ? values.bloodSamples
              : [];
            newBloodSamplesArray.push({
              ...structureFields(values, vals),
              key: Math.random(),
            });
            setValues({
              ...values,
              bloodSamples: newBloodSamplesArray,
            });
            bloodSampleForm.resetFields();
            if (current === 16) setFormSubmitting(true);
            else setCurrent(current + 1);
            break;
          default:
            break;
        }
      } else if (visit === "j1" || visit === "j8") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await visitInformationsForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          // case 2:
          //   setCurrent(current + 1);
          //   break;
          case 2:
            vals = await bloodSampleForm.validateFields();
            setValues({
              ...values,
              bloodSamples: [
                { ...structureFields(values, vals), key: Math.random() },
              ],
            });
            bloodSampleForm.resetFields();
            setCurrent(current + 1);
            break;
          case 3:
            vals = await bloodSampleForm.validateFields();
            const newBloodSamplesArray = values.bloodSamples
              ? values.bloodSamples
              : [];
            newBloodSamplesArray.push({
              ...structureFields(values, vals),
              key: Math.random(),
            });
            setValues({
              ...values,
              bloodSamples: newBloodSamplesArray,
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await visitInformationsForm.validateFields();
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setCurrent(current + 1);
            break;
          // case 2:
          //   setCurrent(current + 1);
          //   break;
          case 2:
            vals = await bloodSampleForm.validateFields();
            setValues({
              ...values,
              bloodSamples: [
                { ...structureFields(values, vals), key: Math.random() },
              ],
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      // Nvm
    }
  };
  const showButton = () => {
    if (visit === "j0" || visit === "j7") {
      if (current < 16) return "Suivant";
      return "Terminer";
    }
    if (visit === "j1" || visit === "j8") {
      if (current < 3) return "Suivant";
      return "Terminer";
    }
    if (current < 2) return "Suivant";
    return "Terminer";
  };

  const showStep = () => {
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des volontaires sains qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <VisitInformations
              values={values}
              form={visitInformationsForm}
              visit={visit}
            />
          );
        // case 2:
        //   return <FluidsNumber onChange={onChange} values={values} />;
        case 2:
          return (
            <div style={{ maxWidth: 500 }}>
              <Clinical
                showHeightWeight={false}
                values={values}
                form={clinicalForm}
              />
            </div>
          );
        case 3:
          return (
            <div>
              <Title level={5}> Pré-dose</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"firstTube"}
              />
            </div>
          );
        case 4:
          return <DrugForm form={drugForm} visit={visit} patient={patient} />;
        case 5:
          return (
            <div>
              <Title level={5}> Prélèvement 1</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"secondTube"}
              />
            </div>
          );
        case 6:
          return (
            <div>
              <Title level={5}> Prélèvement 2</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"thirdTube"}
              />
            </div>
          );
        case 7:
          return (
            <div>
              <Title level={5}> Prélèvement 3</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"fourthTube"}
              />
            </div>
          );
        case 8:
          return (
            <div>
              <Title level={5}> Prélèvement 4</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"fifthTube"}
              />
            </div>
          );
        case 9:
          return (
            <div>
              <Title level={5}> Prélèvement 5</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"SixthTube"}
              />
            </div>
          );
        case 10:
          return (
            <div>
              <Title level={5}> Prélèvement 6</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"seventhTube"}
              />
            </div>
          );
        case 11:
          return (
            <div>
              <Title level={5}> Prélèvement 7</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"eighthTube"}
              />
            </div>
          );
        case 12:
          return (
            <div>
              <Title level={5}> Prélèvement 8</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"ninthTube"}
              />
            </div>
          );
        case 13:
          return (
            <div>
              <Title level={5}> Prélèvement 9</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"tenthTube"}
              />
            </div>
          );
        case 14:
          return (
            <div>
              <Title level={5}> Prélèvement 10</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"eleventhTube"}
              />
            </div>
          );
        case 15:
          return (
            <div>
              <Title level={5}> Prélèvement 11</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"twelfthTube"}
              />
            </div>
          );
        case 16:
          return (
            <div>
              <Title level={5}> Prélèvement 12</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"Thirteenth"}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else if (visit === "j1" || visit === "j8") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des volontaires sains qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <VisitInformations
              values={values}
              form={visitInformationsForm}
              visit={visit}
            />
          );
        // case 2:
        //   return <FluidsNumber onChange={onChange} values={values} />;
        case 2:
          return (
            <div>
              <Title level={4}> Prélèvement 24h</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"twentyfourth"}
              />
            </div>
          );
        case 3:
          return (
            <div>
              <Title level={4}> Prélèvement 36h</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"thirtysixth"}
              />
            </div>
          );
        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des volontaires sains qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <VisitInformations
              values={values}
              form={visitInformationsForm}
              visit={visit}
            />
          );
        // case 2:
        //   return <FluidsNumber onChange={onChange} values={values} />;
        case 2:
          return (
            <div>
              <Title level={4}> Prélèvement</Title>
              <BloodSample
                values={values}
                form={bloodSampleForm}
                nameProps={"fourtyeigth"}
              />
            </div>
          );
        default:
          return <div />;
      }
    }
  };
  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(visit)}
        thirdSectionName="Ajout d'une visite"
        handleGoBack={closeAdd}
      />
      <Spin spinning={contentIsLoading}>
        <Card>
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium stepsHeader"
          >
            <Step title="Identification" />
            <Step disabled={current < 1} title="Informations" />
            {/* <Step disabled={current < 2} title="Nombre de fluides" /> */}

            {(visit !== "j0" && visit !== "j7") ||
              visit === "j1" ||
              (visit === "j8" && (
                <Step disabled={current < 2} title="Prélèvement" />
            ))}

            {(visit === "j2" || visit === "j9") &&
                <Step disabled={current < 2} title="Prélèvement 48h" />
            }
            
            {visit === "j1" || visit === "j8" ? (
              <>
                <Step disabled={current < 2} title="Prélèvement 24h" />
                <Step disabled={current < 3} title="Prélèvement 36h" />
              </>
            ) : null}

            {visit === "j0" || visit === "j7" ? (
              <>
                <Step disabled={current < 2} title="Clinique" />
                <Step disabled={current < 2} title="Pré-dose" />
                <Step disabled={current < 3} title="Administration" />
                {[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((element) => {
                  return (
                    <Step
                      disabled={current < element}
                      title={`P${element - 4}`}
                    />
                  );
                })}
              </>
            ) : null}
          </Steps>

          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
          {showNextButton ? (
            <Button
              onClick={handleNext}
              type="primary"
              className="m-top-medium m-bottom-medium center-button"
            >
              {showButton()}
            </Button>
          ) : null}
        </Card>
      </Spin>
    </div>
  );
};

AddVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  setPendingListAsync: PropTypes.func.isRequired,
  visit: PropTypes.string.isRequired,
  patientsList: PropTypes.array.isRequired,
  createVisitAsync: PropTypes.func.isRequired,
  setPatientsList: PropTypes.func.isRequired,
  closeAdd: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  patientsList: state.patients.patientsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPendingListAsync,
      createVisitAsync,
      setPatientsList,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AddVisit);
