import React from 'react';
import PropTypes from 'prop-types';
import {
    Form, Input,
} from 'antd';
import DynamicField from '../DynamicField';

const Clinical = ({
    form, values, onUpdate, showHeightWeight, imc,
}) => {
    return (<Form style={{ marginTop: 30 }} form={form}>
        <div>
            {showHeightWeight && ['weight', 'height'].map(k => (
                <div>
                    <DynamicField key={k} field={values[k]} onUpdate={onUpdate} fieldFamily="clinical" />
                </div>
            ))}
            <div style={{ paddingBottom: '16px' }}>
                {imc ? (imc > 18.5 && imc < 30) ? (
                    <p style={{ color: 'green' }}>
                        IMC valeur :
                        {imc}
                    </p>
                ) : (
                    <p style={{ color: 'red' }}>
                        IMC valeur :
                        {imc}
                    </p>
                ) : null}
            </div>
            <DynamicField field={values.bodyTemperature} onUpdate={onUpdate} fieldFamily="clinical" />
            <div>
                <div className="ant-col ant-form-item-label m-bottom-small">
                    <strong> Pression Artérielle </strong>
                </div>
                <Input.Group className="flex-column" name="arterialPression">
                    {
                        values.systoliqueRight
                            ? <DynamicField field={values.systoliqueRight} onUpdate={onUpdate} fieldFamily="clinical" />
                            : null

                    }
                    <DynamicField field={values.systolique} onUpdate={onUpdate} fieldFamily="clinical" />
                    {
                        values.diastoliqueRight
                            ? <DynamicField field={values.diastoliqueRight} onUpdate={onUpdate} fieldFamily="clinical" />
                            : null
                    }
                    <DynamicField field={values.diastolique} onUpdate={onUpdate} fieldFamily="clinical" />
                </Input.Group>
            </div>
        </div>
    </Form>)
};

Clinical.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
    showHeightWeight: PropTypes.bool,
};

Clinical.defaultProps = {
    onUpdate: () => {},
    showHeightWeight: true,
};

export default Clinical;
