import React from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Typography } from 'antd';
import DynamicField from '../DynamicField';
import patientAdditionEmptyVals from '../../sections/Patients/add/emptyValues';

const { Title } = Typography;

const BiologicalExamination = ({ form, values, onUpdate }) => (
  <Form
    style={{ marginTop: 30 }}
    form={form}
    className="biological-examination-form"
  >
    <Card title="Examen Biologique" className="add-antecedent-card">
      <div style={{ marginLeft: "4%" }}>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hématologique
        </Title>
        <div>
          {["hemoglobine", "leucocytes", "Plaquettes"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan inflammatoire
        </Title>

        <div>
          {["VS", "CRP"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan hépatique
        </Title>
        <div>
          {[
            "ASAT",
            "ALAT",
            "Bilirubine totale",
            "Bilirubine conjuguée",
            "Phosphatase alcaline",
            'Réserves alcalines',
          ].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k] || patientAdditionEmptyVals.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan Ionique
        </Title>
        <div>
          {["K+", "NA+", "Ca++", "Mg+"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        <Title style={{ marginBottom: 14 }} level={5}>
          Bilan Rénal
        </Title>

        <div>
          {["Urée", "Créatinine"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>

        <div>
          {["Glycémie", "Protides"].map((k) => (
            <DynamicField
              key={k}
              field={values.biologicalExamination[k]}
              onUpdate={onUpdate}
              fieldFamily="biologicalExamination"
            />
          ))}
        </div>
        {values.gender.value === "Femme" ? (
          <DynamicField
            key="pregnancyTest"
            field={values.biologicalExamination.pregnancyTest}
            fieldFamily="biologicalExamination"
          />
        ) : null}
      </div>
    </Card>
    <Card title="Examen Radiologique" className="add-antecedent-card">
      <div>
        {["thorax", "ECG"].map((k) => (
          <DynamicField
            key={k}
            field={values.radiologicalExamination[k]}
            onUpdate={onUpdate}
            fieldFamily="radiologicalExamination"
          />
        ))}
      </div>
    </Card>
  </Form>
);
BiologicalExamination.propTypes = {
    form: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
};

BiologicalExamination.defaultProps = {
    onUpdate: () => { },
};

export default BiologicalExamination;
