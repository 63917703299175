export default {

    startDate: {
        name: 'startDate',
        label: 'Date de début',
        rules: ['required', 'validDate'],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
        placeholder: 'e.g: 01/01/2000',
    },

    endDate: {
        name: 'endDate',
        label: 'Date de fin',
        rules: [],
        inputStyle: { width: 'min-content' },
        type: 'input',
        strongLabel: true,
        placeholder: 'e.g: 01/01/2000',
    },

    description: {
        name: 'description',
        label: 'Description',
        rules: ['required'],
        type: 'inputTextArea',
        inputTextAreaStyle: { width: '400px', height: '100px' },
        strongLabel: true,
        placeholder: 'Décrire l\'évenement',

    },

    intensity: {
        name: 'intensity',
        label: 'Gravité',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Grave', value: 'Grave' },
            { label: 'Non grave', value: 'Non grave' },
        ],
    },
    causality: {
        name: 'causality',
        label: "Lien de causalité avec l'essai",
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Hautement probable', value: 'Hautement probable' },
            { label: 'Probable', value: 'Probable' },
            { label: 'Possible', value: 'Possible' },
            { label: 'Douteux', value: 'Douteux' },
            { label: 'Non évaluable', value: 'Non évaluable' },
            { label: 'Sans relation', value: 'Sans relation' },
        ],
    },
    correctingTreatment: {
        name: 'correctingTreatment',
        label: 'Traitement correcteur',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    exclusion: {
        name: 'exclusion',
        label: "Sortie d'étude",
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    treatmentChange: {
        name: ['treatmentChange'],
        label: 'Y a-t-il eu changement de traitement ?',
        rules: ['required'],
        type: 'radio',
        strongLabel: true,
        radioFields: [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' },
        ],
    },
    declaration: {
        name: ['declaration'],
        label: 'Déclaration',
        rules: ['required'],
        type: 'radio',
        radioFields: [
            { label: 'Initiale', value: 'Initiale' },
            { label: 'Suivie', value: 'Suivie' }, // NOTE Has extra field already added
        ],
        stronglabel: true,
    },
    issue: {
        name: ['issue'],
        label: 'Issue',
        rules: ['required'],
        type: 'radio',
        radioStyle: { display: 'flex', flexDirection: 'column' },
        radioFields: [
            { label: 'Décès inattendu', value: 'Décès inattendu' }, // NOTE Has extra field already added
            { label: 'Mise en jeu du pronostic vital', value: 'Mise en jeu du pronostic vital' },
            { label: "Nécessite ou prolonge l'hospitalisation", value: "Nécessite ou prolonge l'hospitalisation" }, // NOTE Has 2 extra field already added
            { label: 'En cours', value: 'En cours' },
            { label: 'Incapacité ou invalidité', value: 'Incapacité ou invalidité' },
            { label: 'Autre', value: 'Autre' }, // NOTE Has extra field already added
        ],
        stronglabel: true,

    },
    evolution: {
        name: 'evolution',
        label: 'Evolution',
        rules: ['required'],
        strongLabel: true,
        type: 'radio',
        radioStyle: { display: 'flex', flexDirection: 'column' },
        radioFields: [
            { label: 'Résolu', value: 'Résolu' },
            { label: 'En résolution', value: 'En résolution' },
            { label: 'Résolu avec séquelle', value: 'Résolu avec séquelle' },
            { label: 'Fatal', value: 'Fatal' },
            { label: 'Non connu', value: 'Non connu' },
        ],
    },
};
