import React, { useEffect, useState } from "react";
import { Steps, Card, Spin, Button, Form, Popconfirm } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateTime } from "luxon";
import GeneralInfo from "../../../components/medicalRecord/general";
import MedicalAntecedents from "../../../components/medicalRecord/medicalAntecedents";
import Treatments from "../../../components/medicalRecord/treatments";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import Examinations from "../../../components/medicalRecord/examinations";
import Inclusion from "../../../components/medicalRecord/inclusion";
import Decision from "./Decision";
import { calculateBMI, structureFields } from "../../../helpers/functions";
import { createPatientAsync } from "../../../redux/patients/actions";
import emptyValues from "./emptyValues";
import Clinical from "../../../components/medicalRecord/clinical";
import moment from "moment";

const { Step } = Steps;

const eligibleToStudy = (datetime) => {
  const diff = Math.abs(datetime.diffNow("years").values.years);
  return diff >= 18 && diff <= 55;
};

const AddPatient = ({ contentIsLoading, createPatientAsync, closeAdd }) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [eligiblePatient, setEligibilePatient] = useState(true);
  const [generalInfoForm] = Form.useForm();
  const [clinicalForm] = Form.useForm();
  const [examinationsForm] = Form.useForm();
  const [inclusionForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();
  const [decisionForm] = Form.useForm();
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [IMC, setIMC] = useState(null);
  const [status, setStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [formComplete, setFormComplete] = useState(false);
  const [ageNumber,setAgeNumber] = useState(null);
  
  const onChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const ageValue = values.birthDate &&  new Date().getFullYear() - moment(values.birthDate.value).year() 
  const [inclusion, setInclusion] = useState({
    age: ageValue > 18 && ageValue< 55 ? "Non" : "Oui",
    goodHealth: "",
    contraception: "",
    signedConsent: "",
    smoker: "",
    alcohol: "",
    BMI: values.BMI > 18.5 && values.BMI <30 ? "Non" : "Oui" ,
    biology: "",
    followProcedure: "",
  });
  const [exclusion, setExclusion] = useState({
    anotherTrial: "",
    age: ageValue < 18 || ageValue > 55 ? "Non" : "Oui",
    pharmaceuticals: "",
    chronicalDisease: "",
    allergy: "",
    anomalyECG: "",
    HTA: "",
    antecedents: "",
    drugs: "",
    usageDuringTrial: "",
    VIH: "",
  });

  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "weight":
        setWeight(value);
        break;
      case "height":
        setHeight(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "birthDate":
        const finalAge= new Date().getFullYear() - moment(value).year();
        setAgeNumber(finalAge);
        break;
      case "goodHealth":
      case "contraception":
      case "signedConsent":
      case "smoker":
      case "alcohol":
      case "biology":
      case "followProcedure":
        setInclusion({ ...inclusion, [name]: value });
        break;
      case "anotherTrial":
      case "pharmaceuticals":
      case "chronicalDisease":
      case "allergy":
      case "anomalyECG":
      case "HTA":
      case "antecedents":
      case "drugs":
      case "usageDuringTrial":
      case "VIH":
        setExclusion({ ...exclusion, [name]: value });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (weight !== null && height !== null) {
      setIMC(((weight / Math.pow(height, 2)) * 10000).toFixed(2));
    }
  }, [weight, height]);

  useEffect(() => {
    if (formComplete) {
      setEligibilePatient(!eligiblePatient);
    }else{
      setEligibilePatient(true);
      setEligibilePatient(false);
    }
  }, [formComplete]);
  useEffect(() => {
    if (current === 5) {
      const didInclusionFilled = Object.values(inclusion).every(
        (x) => x === "Oui"
      );
      const didExclusionFilled = Object.values(exclusion).every(
        (x) => x === "Non"
      );
      if (didInclusionFilled && didExclusionFilled) {
        setFormComplete(true);
      }
    }
  }, [inclusion, exclusion]);

  const showStep = () => {
    switch (current) {
      case 0:
        return <GeneralInfo form={generalInfoForm} values={values} ageNumber={ageNumber}            
         onUpdate={onUpdateValidation}
        />;
      case 1:
        return (
          <Clinical
            form={clinicalForm}
            imc={IMC}
            onUpdate={onUpdateValidation}
            values={values}
          />
        );
      case 2:
        return <Examinations values={values} form={examinationsForm} />;
      case 3:
        return (
          <MedicalAntecedents
            onChange={onChange}
            values={values}
            showForm={showForm}
            setShowForm={setShowForm}
          />
        );
      case 4:
        return (
          <Treatments
            onChange={onChange}
            values={values}
            showForm={showForm}
            setShowForm={setShowForm}
          />
        );
      case 5:
        return (
          <Inclusion
            inclusionForm={inclusionForm}
            exclusionForm={exclusionForm}
            values={values}
            onUpdate={onUpdateValidation}
          />
        );
      case 6:
        return (
          <Decision
            form={decisionForm}
            status={status}
            onUpdate={onUpdateValidation}
            values={values}
          />
        );
      default:
        return <div />;
    }
  };
   
  const handleNextStep = async () => {
    try {
      let vals;
      let birthDateDT;
      let BMI;
      switch (current) {
        case 0:
          vals = await generalInfoForm.validateFields();
          const { birthDate, ...restGeneral } = vals;
          birthDateDT = DateTime.fromFormat(birthDate, "dd/MM/yyyy");

          if (eligibleToStudy(birthDateDT)) {
            inclusionForm.setFieldsValue({ age: "Oui" });
            exclusionForm.setFieldsValue({ age: "Non" });
          } else {
            inclusionForm.setFieldsValue({ age: "Non" });
            exclusionForm.setFieldsValue({ age: "Oui" });
          }
          restGeneral.birthDate = birthDateDT.toJSDate();
          setValues({
            ...values,
            ...structureFields(values, restGeneral),
            BMI,
          });
          setCurrent(current + 1);
          break;
        case 1:
          vals = await clinicalForm.validateFields();
          BMI = calculateBMI(vals.weight, vals.height);
          if (BMI >= 18.5 && BMI <= 30) {
            inclusionForm.setFieldsValue({ BMI: "Oui" });
          } else {
            inclusionForm.setFieldsValue({ BMI: "Non" });
          }
          setValues({
            ...values,
            ...structureFields(values, vals),
            BMI,
          });
          setCurrent(current + 1);
          break;
        case 2:
          vals = await examinationsForm.validateFields();
          const { thorax, ECG, ...rest } = vals;
          setValues({
            ...values,
            radiologicalExamination: structureFields(
              values.radiologicalExamination,
              { thorax, ECG }
            ),
            biologicalExamination: structureFields(
              values.biologicalExamination,
              rest
            ),
          });
          setCurrent(current + 1);
          break;
        case 3:
        case 4:
          setCurrent(current + 1);
          break;
        case 5:
          const inclusionVals = await inclusionForm.validateFields();
          const exclusionVals = await exclusionForm.validateFields();

          setValues({
            ...values,
            inclusion: structureFields(values.inclusion, inclusionVals),
            exclusion: structureFields(values.exclusion, exclusionVals),
          });
            setCurrent(current + 1);
          break;
        case 6:
          const decisionVals = await decisionForm.validateFields();
          createPatientAsync({
            ...values,
            decision: structureFields(values.decision, decisionVals),
          });
          closeAdd();
          break;
        default:
          break;
      }
    } catch (err) {
      // err
    }
  };

  function confirm(e) {
    handleNextStep();
  }

  function cancel(e) {
    console.log(e);
    // alert('Click on No');
  }

  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Volontaires sains"
        secondSectionName="Ajout d'un volontaires sains"
        handleGoBack={closeAdd}
      />
      <Spin spinning={contentIsLoading}>
        <Card>
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium"
          >
            <Step title="Identification" />
            <Step disabled={current <= 1} title="Clinique" />
            <Step disabled={current <= 2} title="Biologie & Radio" />
            <Step disabled={current <= 3} title="Antécédents" />
            <Step disabled={current <= 4} title="Traitements" />
            <Step disabled={current <= 5} title="Inclusion" />
            <Step disabled={current <= 6} title="Décision" />
          </Steps>
          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
          {current === 5 && !eligiblePatient ? (
            <Popconfirm
              title="Certain critéres ne permettent pas l'inclusion de ce patient. Etes vous sur de vouloir continuer?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Oui"
              cancelText="Non"
              className="m-top-medium m-bottom-medium center-button"
            >
              <Button
                type="primary"
                className="m-top-medium m-bottom-medium center-button"
                disabled={showForm}
              >
                {current < 6 ? "Suivant" : "Terminer"}
              </Button>
            </Popconfirm>
          ) : (
            <Button
              onClick={handleNextStep}
              type="primary"
              className="m-top-medium m-bottom-medium center-button"
              disabled={
                current === 3
                  ? !!(showForm && values.medicalAntecedents.length === 0)
                  : current === 4
                  ? !!(showForm && values.treatments.length === 0)
                  : false
              }
            >
              {current < 6 ? "Suivant" : "Terminer"}
            </Button>
          )}
        </Card>
      </Spin>
    </div>
  );
};

AddPatient.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  createPatientAsync: PropTypes.func.isRequired,
  closeAdd: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createPatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AddPatient);
