import React from 'react';
import PropTypes from 'prop-types';
import {
    Form, Card, Divider, Typography,
} from 'antd';
import DynamicField from '../DynamicField';
import { calculateBMI } from '../../helpers/functions';

const { Text } = Typography;

const Inclusion = ({
    inclusionForm, exclusionForm, values, onUpdate,
}) => {
     
   return(<div>
        <Card title="Critéres d'inclusion" className="add-antecedent-card">
            <Form
                style={{ marginTop: 30 }}
                form={inclusionForm}
                className="inclusion-form"
            >
                {[
                    'age',
                    'goodHealth',
                    'contraception',
                    'signedConsent',
                    'smoker',
                    'alcohol',
                    'BMI',
                    'biology',
                    'followProcedure',
                ].map(k => (
                    <>
                        <DynamicField
                            key={k}
                            field={values.inclusion[k]}
                            onUpdate={onUpdate}
                            fieldFamily="inclusion"
                        />
                        {k === 'BMI' ? (
                            <Text type="warning">
                                IMC de ce volontaire :&nbsp;
                                {values.BMI
                  || calculateBMI(values.weight.value, values.height.value)}
                            </Text>
                        ) : null}
                        <Divider style={{ margin: '12px 0' }} />
                    </>
                ))}
            </Form>
        </Card>
        <Card title="Critères de non inclusion" className="add-antecedent-card">
            <Form
                style={{ marginTop: 30 }}
                form={exclusionForm}
                className="inclusion-form"
            >
                {[
                    'anotherTrial',
                    'age',
                    'pharmaceuticals',
                    'chronicalDisease',
                    'allergy',
                    'anomalyECG',
                    'HTA',
                    'antecedents',
                    'drugs',
                    'usageDuringTrial',
                    'VIH',
                ].map(k => (
                    <>
                        <DynamicField
                            key={k}
                            field={values.exclusion[k] ? values.exclusion[k] : null}
                            onUpdate={onUpdate}
                            fieldFamily="exclusion"
                        />
                        <Divider style={{ margin: '12px 0' }} />
                    </>
                ))}
                {values.gender.value === 'Femme'

                    ? (
                        <DynamicField
                            key="pregnancyTest"
                            field={values.exclusion.pregnant}
                            onUpdate={onUpdate}
                            fieldFamily="exclusion"
                        />
                    )

                    : null}
            </Form>
        </Card>
    </div>);
};

Inclusion.propTypes = {
    exclusionForm: PropTypes.object.isRequired,
    inclusionForm: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    onUpdate: PropTypes.func,
};

Inclusion.defaultProps = {
    onUpdate: () => {},
};

export default Inclusion;
