/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Row, Col, Tree } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateTime } from "luxon";
import GeneralInfo from "../../components/medicalRecord/general";
import MedicalAntecedents from "../../components/medicalRecord/medicalAntecedents";
import Treatments from "../../components/medicalRecord/treatments";
import HeadBreadCrumb from "../../components/HeadBreadCrumb";
import Examinations from "../../components/medicalRecord/examinations";
import Inclusion from "../../components/medicalRecord/inclusion";
import Decision from "./add/Decision";
import emptyValues from "./add/emptyValues";
import { setContentIsLoading } from "../../redux/loading/actions";
import {
  selectPatient,
  updatePatientAsync,
} from "../../redux/patients/actions";
import { getAllVisitsByPatientAsync } from "../../redux/visits/actions";
import Clinical from "../../components/medicalRecord/clinical";
import QueryModal from "../../components/QueryModal/index";
import { getByPatient as getPatientQueries } from "../../api/query";

import "./styles.css";

const { Step } = Steps;

const SelectedPatient = ({
  selectedPatient,
  contentIsLoading,
  setContentIsLoading,
  getAllVisitsByPatientAsync,
  updatePatientAsync,
  selectPatient,
  visitsHistory,
  user,
}) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [selectedField, selectField] = useState();
  const [patientQueries, setPatientQueries] = useState([]);
  const [selectedFieldQueries, setSelectedFieldQueries] = useState([]);
  const [generalInfoForm] = Form.useForm();
  const [clinicalForm] = Form.useForm();
  const [examinationsForm] = Form.useForm();
  const [inclusionForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();
  const [decisionForm] = Form.useForm();
  const mapFiedFamilyToForm = {
    general: generalInfoForm,
    clinical: clinicalForm,
    biologicalExamination: examinationsForm,
    radiologicalExamination: examinationsForm,
    inclusion: inclusionForm,
    exclusion: exclusionForm,
    decision: decisionForm,
  };

  useEffect(() => {
    return () => {
      selectPatient(null);
    };
  }, []);

  useEffect(() => {
    getAllVisitsByPatientAsync(selectedPatient._id);

    (async () => {
      try {
        const { data } = await getPatientQueries(selectedPatient._id);
        setPatientQueries(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [selectedPatient]);

  useEffect(() => {
    console.log("[debug] - ", { selectedField, patientQueries });
    if (selectedField && patientQueries)
      setSelectedFieldQueries(
        patientQueries.filter(
          (q) =>
            q.fieldFamily === selectedField.fieldFamily &&
            q.fieldName === selectedField.name
        )
      );
    return () => {
      setSelectedFieldQueries([]);
    };
  }, [selectedField, patientQueries]);

  const onChange = (name, value) => {
    // setValues({ ...values, [name]: value });
    updatePatientAsync(selectedPatient._id, {
      fieldFamily: name,
      field: {
        name,
        value,
      },
      visit: 1,
    });
  };

  useEffect(() => {
    console.log("values", values);
  }, [values]);

  const changeProp = (fieldFamily, fieldName, prop, value) => {
    if (fieldFamily === "general" || fieldFamily === "clinical") {
      setValues({
        ...values,
        [fieldName]: {
          ...values[fieldName],
          [prop]: value,
        },
      });
    } else {
      setValues({
        ...values,
        [fieldFamily]: {
          ...values[fieldFamily],
          [fieldName]: {
            ...values[fieldFamily][fieldName],
            [prop]: value,
          },
        },
      });
    }
  };

  const handleUpdateField = (fieldFamily, fieldName, prop, value) => {
    switch (prop) {
      case "value":
        const val = mapFiedFamilyToForm[fieldFamily].getFieldValue(fieldName);
        console.log("selectedField",selectedField)
        let field;
        if (fieldFamily === "general" || fieldFamily === "clinical") {
          field = {
            ...values[fieldName],
            value:
              fieldName === "birthDate"
                ? DateTime.fromFormat(val, "dd/MM/yyyy").toJSDate()
                : val === undefined ? 0 : val,
            readOnly: true,
          };
        } else {
          field = {
            ...values[fieldFamily][fieldName],
            value: val,
            readOnly: true,
          };
        }

        const body = {
          field,
          fieldFamily,
          visit: 1,
        };

        updatePatientAsync(selectedPatient._id, body);
        break;
      case "cancel":
        if (fieldFamily === "general" || fieldFamily === "clinical") {
          mapFiedFamilyToForm[fieldFamily].setFieldsValue({
            [fieldName]: selectedPatient[fieldName].value,
          });
        } else {
          mapFiedFamilyToForm[fieldFamily].setFieldsValue({
            [fieldName]: selectedPatient[fieldFamily][fieldName].value,
          });
        }
        changeProp(fieldFamily, fieldName, "readOnly", true);
        break;
      case "readOnly":
        changeProp(fieldFamily, fieldName, prop, value);
        break;
      case "query":
        if (fieldFamily === "general" || fieldFamily === "clinical") {
          selectField({ ...selectedPatient[fieldName], fieldFamily });
        } else {
          selectField({
            ...selectedPatient[fieldFamily][fieldName],
            fieldFamily,
          });
        }
        setOpenQueryModal(true);
        break;
      default:
        changeProp(fieldFamily, fieldName, prop, value);
        if (fieldFamily === "general" || fieldFamily === "clinical") {
          updatePatientAsync(selectedPatient._id, {
            field: {
              ...values[fieldName],
              [prop]: value,
            },
            fieldFamily,
            visit: 1,
          });
        } else {
          updatePatientAsync(selectedPatient._id, {
            field: {
              ...values[fieldFamily][fieldName],
              [prop]: value,
            },
            fieldFamily,
            visit: 1,
          });
        }
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      setContentIsLoading(true);
      setValues(selectedPatient);
      const keys = Object.keys(selectedPatient);
      keys.forEach((key) => {
        const field = selectedPatient[key];
        switch (key) {
          case "birthDate":
            generalInfoForm.setFieldsValue({
              [key]: DateTime.fromISO(field.value).toFormat("dd/MM/yyyy"),
            });
            break;
          case "biologicalExamination":
          case "radiologicalExamination":
            Object.keys(field).forEach((key) =>
              examinationsForm.setFieldsValue({ [key]: field[key].value })
            );
            break;
          case "inclusion":
            Object.keys(field).forEach((key) =>
              inclusionForm.setFieldsValue({ [key]: field[key].value })
            );
            break;
          case "exclusion":
            Object.keys(field).forEach((key) =>
              exclusionForm.setFieldsValue({ [key]: field[key].value })
            );
            break;
          case "decision":
            Object.keys(field).forEach((key) =>
              decisionForm.setFieldsValue({ [key]: field[key].value })
            );
            break;
          case "medicalAntecedentsProps":
          case "treatmentsProps":
            break;
          case "weight":
          case "height":
          case "systolique":
          case "systoliqueRight":
          case "diastolique":
          case "diastoliqueRight":
          case "bodyTemperature":
            clinicalForm.setFieldsValue({ [key]: field.value });
            break;
          default:
            generalInfoForm.setFieldsValue({ [key]: field.value });
            break;
        }
      });
      setContentIsLoading(false);
    }
  }, [selectedPatient]);

  const showStep = () => {
    switch (current) {
      case 0:
        return (
          <GeneralInfo
            form={generalInfoForm}
            values={values}
            onUpdate={handleUpdateField}
          />
        );
      case 1:
        return (
          <Clinical
            form={clinicalForm}
            values={values}
            onUpdate={handleUpdateField}
          />
        );
      case 2:
        return (
          <Examinations
            values={values}
            form={examinationsForm}
            onUpdate={handleUpdateField}
          />
        );
      case 3:
        return (
          <MedicalAntecedents
            onChange={onChange}
            showForm={showForm}
            setShowForm={setShowForm}
            values={values}
          />
        );
      case 4:
        return (
          <Treatments
            onChange={onChange}
            values={values}
            showForm={showForm}
            setShowForm={setShowForm}
          />
        );
      case 5:
        return (
          <Inclusion
            inclusionForm={inclusionForm}
            exclusionForm={exclusionForm}
            values={values}
            onUpdate={handleUpdateField}
          />
        );
      case 6:
        return (
          <Decision
            values={values}
            form={decisionForm}
            onUpdate={handleUpdateField}
          />
        );
      default:
        return <div />;
    }
  };

  if (!selectedPatient) return <div />;

  const prepareTreeData = (raw) =>
    raw.map((visit) => {
      const children = [];
      if (visit.consultationDate && visit.consultationDate.value) {
        children.push({
          title: `Date de la consultation: ${visit.consultationDate.value.date}`,
          key: "consultationDate",
        });
      }
      if (visit.admissionDate && visit.admissionDate.value) {
        children.push({
          title: `Date d'admission': ${visit.admissionDate.value.date}`,
          key: "admissionDate",
        });
      }
      // if (visit.bloodSamples) {
      //     Object.values(visit.bloodSamples).forEach(prelevement=>{
      //         children.push({
      //             title: `${prelevement.label} : ${prelevement.value}`,
      //             key: `bloodSamples`,
      //         });
      //     })
      // }

      return {
        title: visit.visitNumber.toUpperCase(),
        key: visit._id,
        children,
      };
    });

  return (
    <div className="section-content" id="selected-patient">
      <HeadBreadCrumb
        firstSectionName="Volontaires sains"
        secondSectionName="Visite de séléction"
        thirdSectionName={
          user && user.type === 1
            ? `${selectedPatient.lastName.value} ${selectedPatient.firstName.value}`
            : selectedPatient.customId
        }
        handleGoBack={() => selectPatient(null)}
      />
      <Spin spinning={contentIsLoading}>
        <Row>
          <Col span={16}>
            <Card>
              <Steps
                current={current}
                onChange={setCurrent}
                size="small"
                className="m-bottom-medium"
              >
                <Step title="Identification" />
                <Step title="Clinique" />
                <Step title="Biologie & Radio" />
                <Step title="Antécédents" />
                <Step title="Traitements" />
                <Step title="Inclusion" />
                <Step title="Décision" />
              </Steps>
              <div className="m-top-medium" style={{ padding: 24 }}>
                {showStep()}
              </div>
            </Card>
          </Col>
          <Col span={7} offset={1}>
            <Card style={{ height: "50em", overflowY: "scroll" }}>
              <Tree treeData={prepareTreeData(visitsHistory)} />
            </Card>
          </Col>
        </Row>
      </Spin>
      <QueryModal
        call={selectedPatient}
        open={openQueryModal}
        selectedFieldQueries={selectedFieldQueries}
        field={selectedField}
        handleClose={() => {
          setOpenQueryModal(false);
          console.log("[debug] - ", { selectedField });
        }}
      />
    </div>
  );
};

SelectedPatient.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  selectedPatient: PropTypes.object.isRequired,
  setContentIsLoading: PropTypes.func.isRequired,
  updatePatientAsync: PropTypes.func.isRequired,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
  selectPatient: PropTypes.func.isRequired,
  user: PropTypes.func.isRequired,
  visitsHistory: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  selectedPatient: state.patients.selectedPatient,
  user: state.auth.user,
  visitsHistory: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePatientAsync,
      getAllVisitsByPatientAsync,
      setContentIsLoading,
      selectPatient,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(SelectedPatient);
