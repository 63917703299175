import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal, Tree, Timeline, Table,
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAudit } from '../../redux/patients/actions';
import { formatDateTimeFromIso, getTimeFromISODate } from '../../helpers/functions';
import { medicalAntecedentsColumns } from '../medicalRecord/medicalAntecedents';
import { treatmentsColumns } from '../medicalRecord/treatments';

const showValue = (audit) => {
    switch (audit.fieldFamily) {
        case 'medicalAntecedents':
            return <Table columns={medicalAntecedentsColumns} dataSource={audit.value} locale={{ emptyText: 'Aucun antécédent' }} />;
        case 'treatments':
            return <Table columns={treatmentsColumns} dataSource={audit.value} locale={{ emptyText: 'Aucun traitement' }} />;
        case 'visitInformations':
            if (audit.fieldName === 'lastDrink' || audit.fieldName === 'lastMeal'
                || audit.fieldName === 'admissionDate' || audit.fieldName === 'consultationDate') return `${audit.value.date} - ${getTimeFromISODate(audit.value.time)}`;
            break;
        default: return audit.value;
    }
    return audit;
};

const prepareTree = (c, i) => {
    const res = [];
    res.push({
        title: (
            <>
                <b>
                    {'Date : '}
                </b>
                {formatDateTimeFromIso(c.createdAt)}
            </>
        ),
        key: `${i}-${c.date}`,
        children: [
            {
                title: (
                    <>
                        <b>
                            {i === 0 ? 'Valeur initiale : ' : 'Nouvelle valeur : '}
                        </b>
                        {showValue(c)}
                    </>
                ),
                key: `${i}-${c.value}`,
            },
        ],
    });
    return res;
};

const AuditTrial = ({ selectedAudit, selectAudit }) => (
    <Modal width={820} visible={selectedAudit} title="Historique des modifications" footer={null} onCancel={() => selectAudit(null)}>
        {selectedAudit && selectedAudit.map((audit, i) => (
            <Timeline.Item key={audit._id}>
                <Tree treeData={prepareTree(audit, i)} />
            </Timeline.Item>
        ))}
    </Modal>
);


AuditTrial.propTypes = {
    selectedAudit: PropTypes.array,
    selectAudit: PropTypes.func.isRequired,
};

AuditTrial.defaultProps = {
    selectedAudit: null,
};

const stateToProps = state => ({
    selectedAudit: state.patients.selectedAudit,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectAudit,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AuditTrial);
